import type { Ref } from "vue";

import type { QuestDay } from "@/types";

const getPrize = (day: QuestDay) =>
	day?.tasks?.reduce((acc, item) => acc + ((item.prize?.entries || item.prize?.coins) ?? 0), 0);
const getQuestPrizeSum = (days: Array<QuestDay>) => days?.reduce((acc, item) => acc + getPrize(item), 0);

const useGameQuest = (gameId: Ref<number | undefined> = ref()) => {
	const isGuest = useIsGuest();
	const loginGuard = useLoginGuard();
	const { isSweepStakes } = useSwitchMode();
	const { open, close, isOpen } = useFunrizeModals();
	const { checkDailyOpen, openModalOnce } = useWelcomeModal(open, close);
	const { popupState } = usePopupsState();

	const { data: questPageInfo, refresh: refreshQuestPageInfo } = useGetPageQuestData({
		immediate: !isGuest.value,
		watch: [isGuest]
	});
	const {
		data: questInfo,
		refresh: refreshQuestInfo,
		isSeasonQuest,
		isFreeSpinQuest,
		isAdventQuest
	} = useQuestData({
		immediate: !isGuest.value,
		watch: [isGuest]
	});

	const questData = computed(() => questInfo.value);

	const dayPrizePool = computed(() =>
		questInfo.value.dayInfo?.tasks?.reduce(
			(accumulator, task) => accumulator + (task?.prize?.freeSpin?.freeSpinCount ?? 0),
			0
		)
	);

	const currentTask = computed(() => questData.value?.dayInfo?.tasks?.find(({ isCompleted }) => !isCompleted));
	const isCompletedAllTasks = computed(
		() =>
			!!questData.value?.dayInfo?.tasks?.length &&
			questData.value?.dayInfo?.tasks?.every(({ isCompleted }) => isCompleted)
	);
	const questPlayerData = computed(() => questPageInfo.value?.playerData);
	const lastCompletedTask = computed(
		() => (isCompletedAllTasks.value && questData.value?.dayInfo?.tasks?.at(-1)) || {}
	);

	const isGameInTask = computed(() => {
		if (currentTask.value?.action?.type !== "gamesList" && currentTask.value?.action?.type !== "game") {
			return false;
		}

		return currentTask.value.action?.details?.games?.some((game) => {
			if (typeof game === "object") {
				return game.id === gameId.value;
			}
			return false;
		});
	});
	const isDisplayNextTaskQuest = computed(() => currentTask.value && isSweepStakes.value && isGameInTask.value);
	const taskProgressData = computed(() =>
		currentTask.value?.repeat && currentTask.value?.repeat.goal !== 1
			? currentTask.value.repeat
			: currentTask.value?.progress
	);
	const progress = computed(() => {
		if (currentTask.value?.isCompleted) {
			return "100%";
		}
		if (taskProgressData.value) {
			return `${(100 / taskProgressData.value.goal) * taskProgressData.value.complete}%`;
		}

		return "12px";
	});

	const playerDays = computed(() => questPlayerData.value?.days ?? []);
	const questPrizeSum = computed(() => getQuestPrizeSum(playerDays.value as Array<QuestDay>));

	const handleFreeSpinQuest = () => {
		if (isFreeSpinQuest.value && !questData.value?.dayInfo?.isCompleted) {
			openModalOnce({
				storage: "showFreeSpinWelcomePopup",
				storageSkip: "showFreeSpinFeature",
				modal: "LazyOModalFreeSpinWelcome",
				endDate: questData.value?.questInfo?.end ?? ""
			});
			if (isOpen("LazyOModalFreeSpinWelcome")) {
				return;
			}
			checkDailyOpen({
				storage: "showFreeSpinFeature",
				modal: questData.value?.questInfo?.isAvailable ? "LazyOModalFreeSpinFeature" : "LazyOModalFreeSpinAlternative"
			});
		}
	};

	const unwatchAdventQuest = watch(isAdventQuest, (value) => {
		if (!value) {
			return;
		}

		if (
			!localStorage.getItem("showAdventCalendarIntro") &&
			!isOpen("LazyOModalAdventCalendarIntro") &&
			popupState.value.isLoginFlow
		) {
			open("LazyOModalAdventCalendarIntro");
		}
	});

	const unwatchFreeSpinQuest = watch([isGuest, isFreeSpinQuest], ([newAuth, newFreeSpin]) => {
		if (!newFreeSpin && !newAuth) {
			return;
		}
		handleFreeSpinQuest();
	});

	onMounted(() => {
		loginGuard({
			success: () => {
				handleFreeSpinQuest();
			}
		});
	});

	onBeforeUnmount(() => {
		unwatchAdventQuest();
		unwatchFreeSpinQuest();
	});

	return {
		currentTask,
		dayPrizePool,
		isCompletedAllTasks,
		questPageInfo,
		questData,
		questPlayerData,
		lastCompletedTask,
		isGameInTask,
		isDisplayNextTaskQuest,
		isSeasonQuest,
		taskProgressData,
		progress,
		isFreeSpinQuest,
		isAdventQuest,
		playerDays,
		questPrizeSum,
		refreshQuestPageInfo,
		refreshQuestInfo
	};
};
export default useGameQuest;
